.footer {
  position: absolute;
  width: 100%;
  height: 5rem;
  bottom: 0;
  background-color: rgb(15, 15, 15);
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-content {
    width: 35rem;
    align-items:center;
    .footer-links {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      a {
        text-align: center;
        color: dimgrey;
        text-decoration: none;
        margin-left: 1rem;
        width: fit-content;
      }
    }
  }
}