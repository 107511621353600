.mobile-game-nav-container {
  position: absolute;
  bottom: 9%;
  display: none;
  @media (max-width: 768px){
    margin-left: 0;
    display: block;
  }
  .mobile-game-nav-row {
    height: 25vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    button {
      background-color: grey;
      border: 1px solid black;
      height: 30%;
      min-width: 33%;
      max-width: 50%;
    }
  }
}