.create-player-container {
  height: 28rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .create-player-form-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .create-player-form {
      width: 55%;
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: 15%;
      .create-player-name-input {
        width: 100%;
        height: 2rem;
        text-align: center;
        font-size: 16px;
      }
      .create-player-submit-button {
        border: none;
        font: inherit;
        line-height: 1;
        margin: 0.5em;
        padding: 1em 1em;
        transition: 0.25s;
        border-radius: 2px;
        color: black;
        font-weight: bold;
        background-color: #ffbd3f;
        bottom: 2rem;
        pointer-events: auto;
        width: 70%;
      }

      .submit-button:hover,
      .submit-button:focus {
        box-shadow: 0 0.5em 0.5em -0.4em #ffbd3f;
        background-color: #00ce7a;
          transform: translateY(-0.25em);
      }
    }
  }
}