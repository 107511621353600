.profile-scene {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .profile-scene-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    align-items: center;
    .submit-profile-edit-buttons {
      width: 100%;
      height: 100%;
      display: flex;
      .game-button {
        max-height: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      flex-direction: column;
      justify-content: end;
      align-items: center;
      margin-bottom: 3rem;
    }
    .profile-scene-content-header {
      height: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .profile-picture-row {
        margin-top: 3rem;
        button {
          background-color: transparent;
          border: none;
        }
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }
      .username-content-row {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        input {
          width: fit-content;
        }
        span {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        button {
          background-color: transparent;
          border: none;
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 1rem;
          margin-left: -2rem;
          cursor: pointer;
        }
      }
    }
  }
}