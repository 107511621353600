/* Banner.css */
.error-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.8rem;
    background-color: #ff0000; /* Customize as needed */
    color: #ffffff; /* Customize text color */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
  }
  
  .close-button {
    cursor: pointer;
    font-size: 18px;
    margin-right: 10px;
  }
  
  .error-message {
    flex-grow: 1; /* This centers the error message */
    text-align: center; /* Center the text horizontally */
  }