.guess-container {
    width: 100%;
    height: fit-content;
    margin-bottom: 2rem;
    .guess-header {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      .guess-form {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: center;
        width: 90%;

        .guess-header-left-side {
          display: flex;
          max-width: 30%;
          min-width: 30%;
          height: 100%;
          background-color: transparent;
          .header-submit-button {
            background-color: #00ce7a;
            transition-duration: .5s;
            height: 2.3rem;
            &.green {
              background-color: #00ce7a;
            }
            &.yellow {
              background-color: #ffbd3f;
            }
            &.red {
              background-color: #ce003e;
            }
            &.gray {
              background-color: #3f3f3f;
              color: black;
            }
            &:hover {
              box-shadow: 0 0 10px 2px #ffbd3f;
            }
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border: none;
            width: 100%;
          }
        }
        .guess-header-right-side {
          min-width: 70%;
          max-width: 70%;
          display: flex;
          flex-direction: column;

          .input-wrapper {
            display: flex;
            flex-direction: column;
            height: fit-content;
            .autocomplete-wrapper {
              align-self: center;
              animation: dropdown-fall;
              animation-duration: 1s;
              background-color: cornsilk;
              text-align: center;
              width: 80%;
              z-index: -3;
              font-size: 14px;
              height: 2rem;
              @keyframes dropdown-fall {
                0% {
                  transform: translateY(-10px);
                }
                100% {
                  transform: translateY(0);
                }
              }
            }
            input {
              height: 2.3rem;
              &.active {
                border-bottom-right-radius: 0;
              }
              border: none;
              text-align: center;
              margin: 0;
              padding: 0;
              min-height: 2.3rem;
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              background-color: cornsilk;
              width: 100%;
            }
          }
        }

      }
    }
  .guess-content {
    width: 100%;
    display:flex;
    justify-content: end;
    .guess-content-body {
      width: 63%;
      height: 9rem;
      margin-right: 5%;
      background-color: cornsilk;
      display: flex;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      flex-direction: row;
      .guess-content-left-side {
        width: 5rem;
        margin: .5rem;
        img {
          width: 5rem;
          height: 8rem;
          border-radius: 16px;
        }
      }
      .guess-content-right-side {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        width: 100%;
        h3 {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 1rem;
        }
        h5 {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }

    }

  }
  }
