.user-review-widget-container {
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .user-review-widget {
    background-color: cornsilk;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    max-height: 9rem;
    border-radius: 10px;
    box-shadow: -1em 1em 2em -0.4em #000000;
    scrollbar-width: none;
    .user-review-header {
      margin-left: .4rem;
      margin-right: .4rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .user-review-header-content {
        display: flex;
        margin-top: 1rem;
        margin-left: 4rem;
        margin-right: 4rem;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
        .user-avatar-container {
          height: 4rem;
          width: 4rem;
          display: flex;
          justify-content: end;
          img {
            min-height: 4rem;
            min-width: 4rem;
            border-radius: 50%;
            box-shadow: -.5em .5em 1em -0.4em #000000;
          }
        }
        .review-score-container {
          display: flex;
          height: 4rem;
          width: 4rem;
          justify-content: flex-end;
          align-items: flex-end;
          .review-score {
            display: flex;
            min-height: 4rem;
            min-width: 4rem;
            justify-content: center;
            box-shadow: .5em .5em 1em -0.4em #000000;
            align-items: center;
            border-radius: 50%;
            h2 {
              color: black;
            }
            &.red{
              background-color: #ce003e;
            }
            &.yellow {
              background-color: #ffbd3f;
            }
            &.green {
              background-color: #00ce7a;
            }
        }
        }
        .name-and-date-container{
          min-width: 70%;
          width: 90%;
          max-width: 90%;
          .user-name {
          }
          .review-date {

          }
        }

      }
    }
    .user-review-body {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: clip;
      border-top: 1px solid black;
      margin: 1rem;
      .user-review-body-content {
        text-align: start;
        width: 80%;
        margin: 1.5rem;
      }
    }

  }
  ::-webkit-scrollbar {
    scrollbar-width: none;
  }

}
