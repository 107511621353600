.home-page-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .card-header {
    width: 17rem;
    z-index: 100
  }
  .hosting-section-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    .hosting-section-header {
      width: 80%;
      margin-bottom:-15rem;
    }
  }
  .rules-header {
      display: flex;
      justify-content: center;
      color: #f2f2f2;
      margin-top: 12%;
      margin-bottom: 7%;
    }
    .rules-body {
      display: flex;
      flex-direction: column;
      width: 80%;
      color: #f2f2f2;
      li {
        margin-left: 7%;
        margin-bottom:4%;
        font-size: 17px;
        font-weight: bold;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .dle-button {
        animation: wiggle 2s linear infinite;
        transform-origin: 50% 5em;
        max-height: 100%;

        background-color: #00ce7a;
      }
      @keyframes wiggle {
        0%, 7% {
          transform: rotateZ(0);
        }
        15% {
          transform: rotateZ(-3deg);
        }
        20% {
          transform: rotateZ(8deg);
        }
        25% {
          transform: rotateZ(-8deg);
        }
        30% {
          transform: rotateZ(4deg);
        }
        35% {
          transform: rotateZ(-2deg);
        }
        40%, 100% {
          transform: rotateZ(0);
        }
      }

      body {
        background: #000;
      }
      @keyframes a {
        to {background-position:0% -200%}
      }
    }
}