.terms-of-service-scene {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .terms-of-service-card {
    display: flex;
    border-radius: 15px;
    max-width: 29rem;
    width: 30%;
    height: 85%;
    background-color: cornsilk;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    max-height: 39rem;
    box-shadow: 0 .5rem .7rem 0 #000000;

    .terms-of-service-text-container {
      display: flex;
      max-height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow-y: hidden;
      margin-bottom: 3rem;
      margin-left: 3rem;
      .terms-of-service-text-body {
        scrollbar-color: transparent transparent;
        overflow-y: scroll;
        text-align: center;
        margin-right: 3rem;

        ol {
          li {
            width: 100%;
            margin-bottom: 1rem;
            text-align: left;
          }
        }
      }
    }
  }
  .game-button {
    margin-top: 2rem;
    width: 20rem;
  }
}

