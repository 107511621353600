.lobby-section-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    .room-code-input {
      height: 3.5rem;
      text-align: center;
      margin-bottom: 1rem;
      @media (max-width: 768px){
        height: 2.3rem;
        width: 10rem;
      }
  }
}