.game-timer-container {
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 12px;
  border: 2px solid black;
  max-height: 5rem;
  margin-bottom: 1rem;
  .game-timer-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    h1 {
      color: black;
    }
  }
}