.item-container {
  background-color: cornsilk;
  &.unlocked {
    background-color: #8a8570;
  }
  border-radius: 20px;
  width: 90%;
  min-width: 80%;
  margin-bottom: 2rem;
  .item-trigger-container {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    border-radius: 20px;
    z-index: 10;

    .item-header {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      pointer-events: none;
      height: 2rem;
    }

    .item-content-container {
      max-height: 0;
      height: 0;
      border-radius: 20px;
      transition: 3s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      .item-content {
        height: 0;
      }
      &.expanded {
        max-height: fit-content;
        height: fit-content;
        transition: max-height 1s ease-in-out;
        margin: 1rem;
        .item-content {
          height: 100%;
        }
      }
    }
  }
}