.leaderboard-player-container {
  width: 80%;
  height: 4rem;
  background-color: cornsilk;
  margin-top: 1rem;
  border-radius: 4px;
  transition-duration: 1s;
  box-shadow: black .2rem .2rem .2rem;
  &.guessed {
    animation: flash-on-guess;
    animation-duration: 1s;
    transition-duration: 1s;
    background-color: #00ce7a;
  }
  @keyframes flash-on-guess {
    0% {
      background-color: cornsilk;
    }
    25% {
      box-shadow: #ffbd3f 1rem 1rem;
      background-color: #64ffba;
    }
    100% {
      background-color: #00ce7a;
    }
  }
  .leaderboard-player {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .leaderboard-player-name {
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .leaderboard-player-score {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}