.game-active-window {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-flow: column;
    width: 100%;
    height: 100%;

  .game-over-container {
    margin-top: 14rem;
    .results-message {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      height: 100%;
      h4 {
        text-align: center;
      }
      .actual-score-container {
        border: none;
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 0.8rem 0.5rem -0.4rem #000000;
        width: 4.5rem;
        margin-top: -.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.5rem;
        @media (max-width: 768px){
          height: 3rem;
          width: 3rem;
        }
        h1 {
          text-align: center;
        }
      }
    }
    .game-over-button-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: -3rem;
      @media (max-width: 768px){
        flex-direction: row;
        margin-bottom: -1rem;
        button {
          max-width: 30%;
        }
      }
      button {
        border: none;
        font: inherit;
        line-height: 1;
        padding: 1em 1em;
        width: 11rem;
        transition: 0.25s;
        border-radius: 10px;
        color: black;
        font-weight: bold;
        background-color: #ffbd3f;
        pointer-events: auto;
      }

      button:hover,
      button:focus {
        box-shadow: 0 0.5em 0.5em -0.4em #ffbd3f;
        background-color: #00ce7a;
        transform: translateY(-0.25em);
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    width:90%;
    height: 30%;
    margin-bottom: 1rem;
    .score-and-submit-container {
      display: flex;
      width: 100%;
      margin-top: 2rem;
      border-top: 1px solid #929292;
      .score-input-field {
        display: flex;
        text-align: center;
        width: 4.5rem;
        height: 4rem;
        @media (max-width: 768px){
          height: 3rem;
          width: 3.5rem;
          font-size: 2rem;
          margin-top: 3rem;
        }
        font-size: 2.6rem;
        color: black;
        border: none;
        border-radius: 10px;
        margin-left: 30%;
        margin-top: 2rem;
        transition-duration: 1s;
        box-shadow: 0 0.8rem 0.5rem -0.4rem #000000;

        &.red {
          background-color: #ce003e;
        }
        &.yellow {
          background-color: #ffbd3f;
        }
        &.green {
          background-color: #00ce7a;
        }
        &.gray {
          background-color: #3f3f3f;
        }
      }
      .submit-button {
        width: 40%;
        height: 3rem;
        border: none;
        font: inherit;
        line-height: 1;
        padding: 1em 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.25s;
        border-radius: 10px;
        color: black;
        font-weight: bold;
        background-color: #00ce7a;
        pointer-events: auto;
        margin-top: 3.3rem;
        margin-bottom: -.3rem;
        transition-duration: 1s;
      }

      .submit-button:hover,
      .submit-button:focus {
        box-shadow: 0 0.5em 0.5em -0.4em #00ce7a;
        background-color: #ffbd3f;
        transform: translateY(-0.25em);
      }
    }
    .value-slider {
      transition-duration: 1s;
      width: 100%;
      margin-top: 5%;
      &.red {
        color: #ce003e;
      }
      &.yellow {
        color: #ffbd3f;
      }
      &.green {
        color: #00ce7a;
      }
      &.gray {
        color: #3f3f3f;
      }
    }
  }
  .game-header-container {
    display: flex;
    height: 2rem;
    position: absolute;
    top: -1.1rem;
    justify-content: center;
    align-items: center;
    width: 25.7rem;
    .movie-title-container {
      font-weight: bold;
      color:cornsilk;
      border-style:double;
      width: fit-content;
      height: 2rem;
      position: absolute;
      border-width: 1px;
      border-radius: 15px;
      border-color: rgb(199, 253, 197);
      background-color: #3f3f3f;
      h3{
        margin-top: .3rem;
        font-size: 1.2rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      h4{
        margin-top: .3rem;
        text-align: center;
        font-size: .8rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}