
.movie-image-container {
  height: 40%;
  width: 90%;
  background-color:rgb(82, 82, 82);
  position: absolute;
  border-radius: 15px;
  top: 2rem;
  justify-content: center;
  display: flex;
  pointer-events: none;
  align-items: center;
  box-shadow: -.2rem .5rem .7rem 0 #000000;

  &.unlocked {
    pointer-events: auto;
    @media (max-width: 768px) {
      pointer-events: none;
    }
  }

  h3{
    transition-duration: 5s;
    transition-delay: 2s;
    display: none;
  }

  &.toggled {
    background-color:rgb(31, 31, 31);
  }
  button {
    height: 100%;
    width: 100%;
    opacity: 0;
    position:absolute;
  }

  .locked-image-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .locked-image {
      width: 20%;
      height: 20%;
    }
  }

  .movie-poster-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition-duration: .5s;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px;
    &.toggled {
      @media (max-width: 768px) {
        &.mobile-active {
          height: 73vh;
          width: 90vw;
          min-width: 90vw;
          pointer-events: all;
          display: flex;
          position: fixed;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      height: 73vh;
      width: 28vw;
      top: -20%;
      left: 112%;
      position: absolute;
      pointer-events: all;
  }
}


  img::before {
    opacity: 0;
  }
}
.movie-image-container:hover {
  &.toggled{
    opacity: 100%;
  }
  h3 {
    text-align: center;
    width: 10rem;
    height: 2rem;
    opacity: 96%;
    z-index: 15;
    display: block;
    margin-top: 25%;
    border-radius: 20px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    background-color: #37474F;
  }
  opacity:95%;
}