.game-info-column {
  width: 66.6%;
  border-left: 1px solid black;
  height: 100%;
  .game-info-column-inactive {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    h2 {
      color: cornsilk;
    }
  }
  .game-info-column-items-container {
    display: flex;

    height: 80%;
    flex-direction: column;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    overflow-y: scroll;
    align-items: center;
  }
  .guess-items-container {
    h2 {
      margin-top: 1rem;
      color: #ffbd3f;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .5rem;
    width: 100%;
    height: 100%;
  }
  .unlock-items-container {
    h2 {
      margin-top: 1rem;
      color: #ffbd3f;
      &.red {
        color: #ce003e;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-bottom: 2px solid #030303;
    align-items: center;
  }
  .game-info-column-header {
    border-bottom: 2px solid #030303;
    height: 8.7%;
    h2{
      text-align: center;
      color: #00ce7a;;
    }
  }
}