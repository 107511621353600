.user-card-container {
  height: 100%;
  width: 14%;
  pointer-events: none;
  button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: 1;
    .user-card-content {
      display: flex;
      flex-direction: row;
      width:fit-content;
      height: 100%;
      justify-content: space-evenly;
      &:hover {
        background-color: #1a1a1a
      }
      background-color: #232323;
      align-items: center;
      margin-left: 4rem;
      border-right: 1px solid #545454;
      border-left: 1px solid #545454;
      padding-left: 2rem;
      padding-right: 2rem;
      img {
        width: 3.4rem;
        height: 3.4rem;
        border-radius: 14px;
      }
      h2 {
        color: cornsilk;
        margin-left: 1rem;
        font-family: "Courier New";
      }
    }
  }
}