.create-session-form {
  flex-direction: column;
  display: flex;
  width: 85%;
  height: 100%;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;

  .create-session-inputs-container {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      margin-top: -.6rem;
      height: 4rem;
      @media (max-width: 768px){
        height: 2.4rem;
      }

      input {
        width: 2rem;
        height: 2rem;
        border-radius: 10px;
        border-style: none;
        text-align: center;
        @media (max-width: 768px){
          height: 1.3rem;
          width: 1.3rem;
        }
      }

      h4 {
        width: 9rem;
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: start;
        height: 1.2rem;


        div {
          text-align: start;
          margin-top: -.3rem;
        }
      }
    }
  }

  .bottom-form-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid;
    height: 6rem;
    margin-bottom: 1rem;
    width: 100%;
    border-color: #3f3f3f;
    justify-content: center;
    align-items: center;



    .create-session-toggles-container {
      margin-top: 1rem;

      div {
        height: 2rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          height: 100%;
        }
      }
    }
  }

  .room-code-input {
    width: 10rem;
    height: 2rem;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    border: none;
    margin-bottom: 1rem;
    @media (max-width: 768px){
      height: 2rem;
    }
  }

  .bottom-form-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
    @media (max-width: 768px){
      flex-direction: row;
      button {
        font-size: 10px;
        max-width: 100%;
        max-height: 2.5rem;
      }
    }
    .submit-button {
      width: 75%;
      max-height: 3rem;
    }
  }
}