$root: '.c-rainbow';

:root {
 --color-background: #31037D;
 
 --axis-x: 1px;
 --axis-y: 1rem;
 --delay: 10;
 
 --color-black: #000;
 --color-white: #fff;
 --color-orange: #D49C3D;
 --color-red: #D14B3D;
 --color-violet: #CF52EB;
 --color-blue: #44A3F7;
 --color-green: #5ACB3C;
 --color-yellow: #DEBF40;
 
 --color-foreground: var(--color-white);
 --font-name: Righteous;
}

#{$root} {
 display: flex;
 counter-reset: rainbow;
 list-style: none;
 justify-content: center;
 align-items: center;
 
 &__layer {
  --text-color: var(--color-foreground);
  counter-increment: rainbow; 
  font-size: 3rem;
  color: var(--text-color);

  text-shadow:
   -1px -1px 0 var(--color-black),  
    1px -1px 0 var(--color-black),
    -1px 1px 0 var(--color-black),
     1px 1px 0 var(--color-black),
   
     4px 4px 0 rgba(0, 0, 0, .2);
  
  animation: rainbow 1.5s ease-in-out infinite;
  
  @for $i from 1 through 7 {
   &:nth-child(#{$i}) {
    animation-delay: calc( #{$i} / var(--delay) * 1s);
    left: calc(var(--axis-x) * #{$i});
    z-index: -#{$i * 10};
   }
  }
  
  &{
   position: absolute;
   width: 100%;
  }

  &--white  { --text-color: var(--color-white)}
  &--orange { --text-color: var(--color-orange)}
  &--red    { --text-color: var(--color-red)}
  &--violet { --text-color: var(--color-violet)}
  &--blue   { --text-color: var(--color-blue)}
  &--green  { --text-color: var(--color-green)}
  &--yellow { --text-color: var(--color-yellow)}
 }
}

@keyframes rainbow {
 0%, 100% {
  transform: translatey(var(--axis-y)); 
 }
 50% {
  transform: translatey(calc(var(--axis-y) * -1));
 }
} 