.player-guess-result-item {
  width: 100%;
  max-height: fit-content -1px;
  &:focus,
  &:hover,
  &:active{
    border: 1px solid cornsilk !important;
  }
  z-index: 9999;
  background-color: #0e0e0e;
  border-radius: 10px;
  box-shadow: black .2rem .2rem .2rem;
  transition-duration: 1s;
  border: 1px solid cornsilk;
  margin-bottom: 1rem;
  &.expanded {
    max-height: fit-content;
    transition-duration: 1s;
  }
  .player-guess-result-header {
    background-color:transparent;
    border-radius: 10px;
    padding: .9rem;
    @media (max-width: 768px) {
      padding: .4rem;
    }
    display: flex;
    margin-top: -.3rem;
    height: 5rem;
    width: 90%;
    justify-content: space-evenly;
    .player-guess-result-header-left-content {
      display: flex;
      height: 100%;
      min-width: 33%;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      img {
        @media (max-width: 768px) {
          font-size: 10px;
          width: 2.6rem;
          height: 2.6rem;
        }
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        box-shadow: black .2rem .2rem .2rem;
      }
      strong {
        color: cornsilk;
      }

    }
    .player-guess-result-header-right-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
      min-width: 33%;
      margin-top: 1rem;
      button {
        background-color: transparent;
        border: none;
        margin-top: 1rem;
        @media (max-width: 768px) {
          margin-top: 0;
        }
        img {
          filter:brightness(0) saturate(100%) invert(100%) sepia(91%) saturate(1175%) hue-rotate(180deg) brightness(127%) contrast(84%);
        }
      }
      h6{
        font-size: .7rem;
        color: cornsilk;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .player-guess-score-container {
      height: 100%;
      min-width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .player-guess-result-header-score {
        background-color: cornsilk;
        height: 3.2rem;
        width: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
        border-radius: 12px;
        box-shadow: black .2rem .2rem .2rem;
        @media (max-width: 768px) {
          font-size: 10px;
          width: 2.6rem;
          height: 2.6rem;
        }
      }
    }
  }
  .player-guess-result-content {
    padding: 1.5rem;
    margin-top: -1rem;
    display: flex;
    flex-direction: row;
    height: 8rem;
    .player-guess-result-left-content {
      .player-guess-result-row {
        display: flex;
        &.red {
          p {
            color: #ce003e;
          }
          img {
            width: 1.2rem;
            filter: brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5697%) hue-rotate(337deg) brightness(80%) contrast(104%);
          }
        }
        p {
          color: #00ce7a;
        }

        img {
          width: 1.2rem;
          filter: brightness(0) saturate(100%) invert(51%) sepia(45%) saturate(2123%) hue-rotate(120deg) brightness(101%) contrast(104%);
        }

      }
    }
    .player-guess-result-content-right {
      width: 50%;
      height: 100%;
      p {
        color: #00ce7a;
      }
      color: #00ce7a;
    }
  }
}

