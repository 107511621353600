.game-info-window-container {
  display: flex;
  position: absolute;
  top: 15%;
  max-width: 35%;
  width: 100%;
  height: 70%;
  left: 15.3%;
  justify-content: end;
  background-color: #1e1e1e;
  @media (max-width: 768px){
    margin-left: 0;
    height: 60%;
    min-width: 90vw;
    left: 150vw;
    &.mobile-active {
      display: flex;
      left: 5%;
      top: 10%;
    }
  }
  border: 1px solid black;
  border-radius: 16px;
  opacity: 90%;
  transition-duration: 1s;
  box-shadow: -.5rem .5rem .7rem 0 #000000;
  animation: expand;
  animation-duration: 1s;
  &.poster-expanded {
    max-width: 30%;
    transition-duration: 1s;
    left: 7vw;
    }
  @keyframes expand {
    0% {
      max-width: 10%;
    }
    100% {
      max-width: 35%
    }
  }
}