.host-waiting-container {
    height: 100%;
    width: 100%;
    .room-code-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .player-count-section {
            margin-top: -3rem;
            width: 8rem;
            height: 4rem;
            border-radius: 12px;
            background-color: #00ce7a;
            text-align: center;
            margin-bottom: 6rem;
        }
        .room-code-value-container {
            margin-top: 5rem;
            background-color: white;
            height: 4rem;
            width: 15rem;
            text-align: center;
            margin-bottom: 5rem;
            border-radius: 12px;
            h3{
                margin-top: .4rem;
            }
        }
    }
}