@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}

@mixin getColorFromRating($rating){
    
}