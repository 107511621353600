@import '../../../mixin.scss';

.game-container {
  display: flex;
  border-radius: 15px;
  max-width: 29rem;
  min-width: 20rem;
  width: 30%;
  height: 85%;
  background-color: cornsilk;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-height: 39rem;
  box-shadow: 0 .5rem .7rem 0 #000000;
  transition-duration: 1s;
  @media (max-width: 768px){
    margin-top: -35%;
    height: 70%;
    left: -100vw;
    &.mobile-active {
      display: flex;
      left: 0;
    }
  }


  &.has-movie {
    transition-duration: 1s;
    margin-left: 32vw;
    @media (max-width: 768px){
      margin-left: 0;
    }
    &.poster-expanded {
      margin-left: 0;
    }
  }

  .score-color-stripe {
    z-index: 1;
    position: absolute;
    background-color: #00ce7a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 12rem;
    @media (max-width: 768px){
      height: 30%;
    }
    width: 100%;
    transition-duration: 1s;
    top:0;

    &.has-movie {
      transition-duration: 1s;
    }
    &.red {
      background-color: #ce003e;
    }
    &.yellow {
      background-color: #ffbd3f;
    }
    &.green {
      background-color: #00ce7a;
    }
    &.gray {
      background-color: #3f3f3f;
    }
  }

  .game-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index:2;
    width: 100%;
    height: 100%;

    .game-card {
      &.has-movie {
        background-color: transparent;
        box-shadow: none;
      }
      display: flex;
      background-color: rgb(86, 97, 97);
      height: 80%;
      box-shadow: 0 0.5em 0.5em -0.4em #000000;
      width: 87%;
      z-index: 9999;
      justify-content: center;
      flex-direction: column;
      top: 7%;
      border-radius: 15px;
      align-items: center;

      .card-header {
        display: flex;
        font-weight: bold;
        max-width: 24rem;
        min-width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        top: -1.3rem;
        color:cornsilk;
        border-style:double;
        position: absolute;
        height: 2rem;
        border-width: 1px;
        border-radius: 15px;
        border-color: rgb(199, 253, 197);
        background-color: #3f3f3f;
        text-align: center;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px){
          width: 70%;
        }
        &.home {
          z-index: 0;
          width: 18rem;
        }
      }
    }
  }
}