.post-round-column {
  width: 80%;
  border-left: 2px solid black;
  overflow-y: scroll;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  .post-round-column-header {
    height: 11.8%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid black;
    h2 {
      color: #00ce7a;
    }
  }
  .post-round-column-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .player-guess-results-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
    h1 {
      color: black;
    }
  }
}