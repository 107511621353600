.landing-page-root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px){
    align-items: start;
  }

  .landing-page-container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-page-info-window {
      width: 60%;
      height: 80%;
      max-width: 60%;
      min-width: 20rem;
      display: flex;
      border-radius: 16px;
      background-color: #1a1a1a;
      border: 1px solid #000000;
      flex-direction: column;
      animation: expand-lading-page;
      animation-duration: 2s;
      align-items: center;
      box-shadow: 0 .5rem .7rem 0 #000000;

      .window-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: center;
        overflow: hidden;
        animation: body-fade-in;
        animation-duration: 2.5s;

        @keyframes body-fade-in {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .left-side-content {
          width: 60%;
          display: flex;
          flex-direction: column;
          height: 100%;
          max-height: 26rem;
          border-right: 1px solid #000000;

          scrollbar-width: none;

          .left-side-content-body {
            margin: .7rem;
            overflow-y: scroll;
            scrollbar-color: transparent transparent;
            max-height: 60%;
            @media (max-width: 768px){
              margin-right: .2rem;
              margin-left: -1rem;
            }

            h2 {
              color: cornsilk;
              text-align: center;
            }

            li {
              margin-bottom: 1rem;
              color: cornsilk;
              &.multiplayer-update-text {
                color: #00ce7a;
              }
            }
          }

          .left-side-content-header {
            height: 5rem;
            min-height: 5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 768px){
              height: 3rem;
              min-height: 3rem;
            }

            button {
              font-family: inherit;
              background-color: #151515;
              border: 1px solid #000000;
              color: cornsilk;
              height: 100%;
              width: 33.3333%;
              transition: 1s;

              &.active {
                transition: 1s;
                background-color: #282828;
              }
            }
          }
        }

        .right-side-content {
          width: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .right-side-content-header {
            text-align: center;
            width: 65%;
            min-width: fit-content;
            border-bottom: 1px solid #000000;
            h2 {
              color: #ce003e;
              margin-bottom: .5rem;
            }
          }

          .right-side-content-body {
            max-height: 80%;
            min-height: 20%;
            margin: 1rem;
            scrollbar-width: none;
            font-size: 11px;
            color: #ce003e;
            text-align: center;
            @media (max-width: 768px){
              margin: .1rem;
            }
            a {
              display: inline-block;
              color: #ffbd3f;
              width: fit-content;
            }
          }
          .right-side-button-container {
            width: 100%;
            height: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            button {
              margin-top: -1.5rem;
              @media (max-width: 768px){
                margin-top: .5rem;
              }
            }
          }

        }
      }


        .window-header {
          height: 4.5rem;
          width: 100%;
          border-bottom: 1px solid #000000;
          display: flex;
          justify-content: center;
          align-items: center;

          h1 {
            animation: wait;
            animation-duration: 2s;
            text-align: center;
            color: #e5dec2;
            @keyframes wait {
              0% {
                opacity: 0;
              }
              50% {
                opacity: 0;
              }
              60% {
                opacity: 85%;
              }
              100% {
                opacity: 1;
              }
            }
          }
        }

        @keyframes expand-lading-page {
          0% {
            max-width: 0;
          }
          50% {
            max-width: 60%;
          }
          100% {
            max-width: 60%;
          }
        }
      }
    }
  }
