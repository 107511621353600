.movie-filter-form-container {
  height: 100%;
  width: 100%;
  margin-top: 1.5rem;
  .movie-filter-form {
    width: 100%;
    height: 100%;
    margin-top: -2rem;
    .date-input-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .min-year-input-row {
        display: flex;
        flex-direction: column;
        max-width: 33%;
        span {
          min-width: fit-content;
          text-align: center;
        }
        input {
          max-height: 2rem;
        }
      }
      .max-year-input-row {
        display: flex;
        flex-direction: column;
        max-width: 33%;
        span {
          min-width: fit-content;
          text-align: center;
        }

        input {
          max-height: 2rem;
        }
      }
    }
    .genre-input-section {
      width: 100%;
      display: flex;
      flex-direction: column;

      .check-box-button-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 75%;
        margin-bottom: 2rem;
        button {
          border: none;
          font-size: 12px;
          margin-left: .4rem;
          background-color: #ffbd3f;
        }
      }


      .genre-input-columns-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: -1rem;
        width: 100%;
      }
      h4 {
        text-align: center;
      }
      .filter-column-left {
        display: flex;
        flex-direction: column;
        .input-row {
          @media (max-width: 768px){
            font-size: 10px;
            input {
              width: .5rem;
              height: .5rem;
            }
          }
          font-size: 14px;
          display: flex;
          flex-direction: row;
          input {
            accent-color: #00ce7a;
          }
          width: 50%;
        }
      }
      .filter-column-right {
        display: flex;
        flex-direction: column;
        .input-row {
          @media (max-width: 768px){
            font-size: 10px;
            input {
              width: .6rem;
              height: .6rem;
            }
          }
          display: flex;
          flex-direction: row;
          width: 100%;
          font-size: 14px;
          input{
            accent-color: #00ce7a;
          }
        }
      }
    }
    .submit-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      .game-button {
        max-width: fit-content;
      }
    }
  }
}