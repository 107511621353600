.leaderboard-column-container {
  display: flex;
  width: 33.3%;
  height: 100%;
  .leaderboard-column {
    width: 100%;
    height: 100%;
    .leaderboard-column-body {
      height: 88.2%;
      margin: .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
    }
    .leaderboard-column-header {
      height: 11.8%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid black;
      .leaderboard-column-header-text {
        color: #ffbd3f;
      }
    }
  }

}