.guest-waiting-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .room-code-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .player-count-section {
            margin-top: 10rem;
            width: 8rem;
            height: 4rem;
            border-radius: 12px;
            background-color: #00ce7a;
            text-align: center;
        }
        .room-code-value-container {
            margin-top: 5rem;
            background-color: white;
            height: 4rem;
            width: 15rem;
            text-align: center;
            margin-bottom: 5rem;
            border-radius: 12px;
        }
    }
}