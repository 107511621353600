@import 'mixin.scss';
@font-face {
  font-family: 'Courier New';
  src: url('../public/fonts/Gabarito-Medium.ttf');
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(43, 43, 43);
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  font-family: "Courier New";
  input {
    font-family: "Courier New";
  }
  button {
    font-family: "Courier New";
  }
  overflow: hidden;

  @media (max-width: 768px){
    font-size: 11px;
    height: 112vh;
    overflow: hidden;
  }

  .App {
    height: 44rem;
    min-width: 100%;
    min-height: 80%;
    max-width: 215vh;
    display: flex;

  }

  .header-stripe {
    position: absolute;
    background-color: rgb(15, 15, 15);
    height: 5rem;
    z-index: 2;
    top: 0;
    width: 100%;
    overflow: hidden;
    @media (max-width: 768px){
      display: none;
      height: 0;
    }
  }


    .home-root {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  button {
    &:focus {
      border: none;
    }
  }

    .game-button {
      border: none;
      font: inherit;
      line-height: 1;
      margin: 0.5em;
      padding: 1em 1em;
      border-radius: 2px;
      color: black;
      font-weight: bold;
      background-color: #ffbd3f;
      bottom: 2rem;
      transition: .6s;
      pointer-events: auto;
      width: 55%;
      max-width: 15rem;
      height: fit-content;
      min-height: 3rem;
      @media (max-width: 768px){
        font-size: 12px;
        min-height: 2rem;
        min-width: 3rem;
        width: fit-content;
      }

      &.disabled {
        background-color: #a9a9a9;
      }

      &.red {
        background-color: #ce003e;
      }
      &.green {
        background-color: #00ce7a;

        :hover {
          box-shadow: 0 0.5em 0.5em -0.4em #ffbd3f;
          background-color: #00ce7a;
          transform: translateY(-0.25em);
        }
      }

      &.return-home-button {
        margin-top: 2rem;
      }
    }

    .game-button:hover{
      box-shadow: 0 0.5em 0.5em -0.4em #ffbd3f;
      background-color: #00ce7a;
      transform: translateY(-0.25em);

      &.disabled {
        background-color: #a9a9a9;
        box-shadow: none;
        transform: none;
      }
    }
  }

