.audio-settings-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .music-volume-slider-container {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px){
      margin-bottom: -1rem;
      max-height: 30%;
    }
    .music-volume-slider {
      color: #00ce7a;
    }
  }
  .ui-volume-slider-container {
    margin-bottom: 2rem;
    @media (max-width: 768px){
      margin-bottom: 0;
      max-height: 30%;
    }
    .ui-volume-slider {
      color: #ffbd3f;
    }
  }
  .settings-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    @media (max-width: 768px){
      max-height: 40%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      &.exit-settings-button {
        background-color: #ffbd3f;
      }
      &.logout-button {
        background-color: #ce003e;
      }
      img {
        margin-left: .5rem;
        width: 1.3rem;
        pointer-events: none;
      }
      h3 {
        pointer-events: none;
      }
      &.muted {
        background-color: #ce003e;
      }
      &.green {
        background-color: #00ce7a;
      }
      width: 10rem;
    }
  }
}