.sources-scene {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .sources-card {
    display: flex;
    border-radius: 15px;
    height: 37rem;
    width: 29rem;
    max-width: 29rem;
    min-width: 29rem;
    background-color: cornsilk;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
    box-shadow: -1em 1em 2em -0.4em #000000;
    .sources-card-body {
      overflow-y: scroll;
      scrollbar-color: transparent transparent;
      margin: 3rem;
      .music-sources {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
        h3 {
          text-align: center;
          width: 15rem;
          border-bottom: 1px solid #000000;
        }
      }
      .tech-sources {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
        h3 {
          text-align: center;
          width: 15rem;
          border-bottom: 1px solid #000000;
        }
      }
    }
  }
  .game-button {
    width: 20rem;
  }
}