.home-page-container {
  height: 28rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 8rem;

    .play-offline-button {
      &.game-button {
        max-height: 2rem;
      }

    }

    .google-login-button {
      background-color: transparent;
      border: none;
      border-radius: 8px;
      img {
        width: 65%;
        transition-duration: 1s;

        &:hover, &:focus {
          transform: translateY(-0.25em);
          box-shadow: 0 0.5em 0.5em -0.4em #5188b4;
          transition-duration: 1s;
        }
      }
    }
  }
}